<template>
  <div>
    <br v-if="isMobile"><br v-if="isMobile">
      <b-row class="justify-content-center">  
          <b-col cols="8" md="4" lg="10" style="margin-top: -72px;">
            <br v-if="isMobile">     <br v-if="isMobile">     
            <center v-if="isMobile"><h4 class="text-white" :style="isMobile ? 'font-size:28px;' : 'font-size:60px;'"><b>Bienvenido</b></h4></center>  
            <center><img src="https://convencioncomercial.com.mx/elementos/jonrronero.png" alt="Image" :style="isMobile ? ' margin-left: -40px; width: 135%;' : 'width:20%;'"   class=" float-left"></center> 
          </b-col>
      </b-row> 
    <center> 
      <b-row class="justify-content-center"  :style="!isMobile ? 'margin-top: -12rem;' : ''">
        <b-col cols="10" md="12" v-if="!isMobile"> 
           <h4 class="text-white" :style="isMobile ? 'font-size:18px;' : 'font-size:60px;'"><b>Bienvenido</b></h4> 
          <br>
        </b-col>
        <b-col cols="6" md="5" lg="2" xl="2">
          <br v-if="isMobile">     
          <img :src="tab == 1 ? 'https://convencioncomercial.com.mx/embajador/reportev.png' : 'https://convencioncomercial.com.mx/embajador/reporteb.png'" class="rounded" alt="Image" :style="isMobile ? 'width:90%;' : 'width:79%; cursor:pointer;'" @click="cambiarTab(1)"> 
        </b-col>
        <b-col cols="6" md="5" lg="2" xl="2"> 
          <br v-if="isMobile">     
          <img  :src="tab == 2 ? 'https://convencioncomercial.com.mx/embajador/rankinv.png' : 'https://convencioncomercial.com.mx/embajador/rankinb.png'"  class="rounded" alt="Image" :style="isMobile ? 'width:90%;' : 'width:79%; cursor:pointer;'" @click="cambiarTab(2)"> 
        </b-col> 
        <b-col cols="12">
          <br><br> 
          <h4 :style="isMobile ? 'font-size:18px; color:#0c273a;' : 'font-size:24px; color:#0c273a;'" v-if="tab == 1">
            Clic sobre el canal para descargar el reporte
          </h4> 
        </b-col>
        <b-col cols="6" md="5" lg="2" xl="2" v-if="tab == 1">
            <img src="https://convencioncomercial.com.mx/embajador/propiob.png" class="rounded" alt="Image" :style="isMobile ? 'width:90%;' : 'width:79%; cursor:pointer;'"  @click="descargaReporte()"> <b-spinner v-if="loader" small></b-spinner>
        </b-col>
        <b-col cols="6" md="5" lg="2" xl="2" v-if="tab == 1"> 
          <img src="https://convencioncomercial.com.mx/embajador/espeb.png" class="rounded" alt="Image" :style="isMobile ? 'width:90%;' : 'width:79%; cursor:pointer;'" @click="descargaReporte4()"> <b-spinner v-if="loader2" small></b-spinner>
        </b-col> 
        <b-col cols="6" md="5" lg="2" xl="2" v-if="tab == 1"> 
          <br v-if="isMobile">   
          <img src="https://convencioncomercial.com.mx/embajador/retailb.png" class="rounded" alt="Image" :style="isMobile ? 'width:90%;' : 'width:79%; cursor:pointer;'" @click="descargaReporte1()"> <b-spinner v-if="loader3" small></b-spinner>
        </b-col> 
        <b-col cols="6" md="5" lg="2" xl="2" v-if="tab == 2">
            <img :src="canal == 3 ? 'https://convencioncomercial.com.mx/embajador/propiov.png' : 'https://convencioncomercial.com.mx/embajador/propiob.png'" class="rounded" alt="Image" :style="isMobile ? 'width:80%;' : 'width:79%; cursor:pointer;'"  @click="cambiaCanal(3)"> 
        </b-col>
        <b-col cols="6" md="5" lg="2" xl="2" v-if="tab == 2"> 
          <img :src="canal == 4 ? 'https://convencioncomercial.com.mx/embajador/espev.png' : 'https://convencioncomercial.com.mx/embajador/espeb.png'" class="rounded" alt="Image" :style="isMobile ? 'width:80%;' : 'width:79%; cursor:pointer;'" @click="cambiaCanal(4)"> 
        </b-col> 
        <b-col cols="6" md="5" lg="2" xl="2" v-if="tab == 2"> 
          <br v-if="isMobile">  
          <img :src="canal == 1 ? 'https://convencioncomercial.com.mx/embajador/retailv.png' : 'https://convencioncomercial.com.mx/embajador/retailb.png'" class="rounded" alt="Image" :style="isMobile ? 'width:80%;' : 'width:79%; cursor:pointer;'" @click="cambiaCanal(1)">  
        </b-col> 
      </b-row> 
      <template v-if="canal == 3 && tab == 2">
        <propio2 v-if="!isMobile"></propio2>
        <propioMovil2 v-else></propioMovil2>
      </template>
      <template v-if="canal == 1 && tab == 2">
        <retail2 v-if="!isMobile"></retail2> 
        <retailMovil2 v-else></retailMovil2>
      </template>
      <template v-if="canal == 4 && tab == 2">
        <especialista2 v-if="!isMobile"></especialista2> 
        <espMovil2 v-else></espMovil2> 
      </template>
    </center>
    <br><br>
  </div>
</template>
<script>
// import Swal from 'sweetalert2'
// import SpinnerLoader from "../../utils/SpinnerLoader.vue";
import propio2 from '../Ranking/Propio2.vue'; 
import retail2 from '../Ranking/Retail2.vue';
import especialista2 from '../Ranking/Especialista2.vue';
import propioMovil2 from '../Ranking/ropioMovil2.vue'; 
import retailMovil2 from '../Ranking/etailMovil2.vue';
import espMovil2 from '../Ranking/espMovil2.vue';
export default {
  name: 'Embajador',
  components:{ 
    propio2,
    retail2,
    especialista2,
    propioMovil2,
    retailMovil2,
    espMovil2
  },
  data(){
    return{
      isMobile: this.$mobile(),
      loader:false,
      loader2:false,
      loader3:false,
      tab:1,
      canal:3,
      arrayGan:[]
    }
  },
  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    }
  },
  mounted(){
     this.$bus.$emit("cambia_embajador", true)
      if(!this.isMobile)
      {
          this.$bus.$emit("fondo_capa", "fondoSolo")
      }
  },
  methods:{
    cambiaCanal(id){
      this.canal = id;
      // this.apibitacora();
    },
    apibitacora(id){
      var seccion = "";
      switch (id) {
        case 1:
          seccion = 'Reporte ranking propio';
        break; 
        case 2:
          seccion = 'Reporte ranking retail';
        break;
        case 3:
          seccion = 'Reporte ranking TLMKT';
        break;
        case 4:
          seccion = 'Reporte ranking especialista';
        break; 
      }
     this.$api.post('auth/bitacora', {
          'correo' : '',
          'mrt' : '',
          'canal' : 'TODOS',
          'perfil' : 'Embajadores',
          'region' : 'N/A',
          'url' :  '/embajador',
          'seccion' : 'Reportes y ranking',
          'accion' : "Descarga",
          'detalle' :  seccion,
        }).then(
          response => {
             console.log(response);
          })
    },
    descargaReporte(){ 
        this.loader = true;
          this.$fileDownload(
                    this.$api.baseUri + 'auth/propio',
                    "reporte-ranking-propio.xlsx",
            ).then(
                () => {
                    this.loader = false;
                    this.apibitacora(1);
                }
            ) 
    },
    descargaReporte1(){ 
          this.loader3 = true;
            this.$fileDownload(
                      this.$api.baseUri + 'auth/retail',
                      "reporte-ranking-retail.xlsx",
              ).then(
                  () => {
                      this.loader3 = false;
                      this.apibitacora(2);
                  }
              ) 
    },
    descargaReporte2(){ 
            this.loader = true;
            this.$fileDownload(
                        this.$api.baseUri + 'auth/tlmkt',
                        "reporte-ranking-tlmkt.xlsx",
              ).then(
                  () => {
                      this.loader = false;
                      this.apibitacora(3);
                  }
              ) 
    },
    descargaReporte4(){ 
            this.loader2 = true;
            this.$fileDownload(
                        this.$api.baseUri + 'auth/especialista',
                        "reporte-ranking-especialista.xlsx",
              ).then(
                  () => {
                      this.loader2 = false;
                       this.apibitacora(4);
                  }
              ) 
    },
    cambiarTab(id){
      this.tab = id;   
    },
    ranki(){
      window.open('https://convencioncomercial.com.mx/ranking-embajador','_blank');
    },
    buscaGanadores(id){
        var url= 'auth/gana?canal='+ id;
            this.$api.get(url).then(
                ({data}) => {
                    console.log(data);
                    this.arrayGan = data.ganadores;      
                    this.canal = id;     
                    this.$confetti.start({
                          particles: [
                              {
                              type: 'rect',
                              },
                              {
                              type: 'circle',
                              },
                          ],
                          defaultColors: [
                              '#000',
                              '#ffffff',
                              '#187abe'
                          ],
                      });
                      setTimeout(() => {
                          this.$confetti.stop();
                      }, 4000);      
                }
            );
    },
    regresarHome(){
      this.$bus.$emit("cambia_fondo", true)
      location.reload();
    }
  },
}
</script>
<style> 
    @font-face {
        font-family: 'TelefonicaLight';
        src: url("https://convencioncomercial.com.mx/fonts/Telefonica-Light.otf") format("opentype");
    }

    @font-face {
        font-family: 'TelefonicaRegular';
        src: url("https://convencioncomercial.com.mx/fonts/Telefonica-Regular.otf") format("opentype");
    }

    * {
        font-family: TelefonicaRegular, serif;
    }

</style>